export const extractSearchParams = () =>
  new URLSearchParams(window.location.search)

export const extractClickIds = () => {
  const params = extractSearchParams()
  const fbclid = params.get("fbclid")
  const gclid = params.get("gclid")

  return {
    ...(fbclid && { fbclid }),
    ...(gclid && { gclid }),
  }
}
