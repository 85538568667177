import Joi from "@hapi/joi"

const multipleFreeTextValidation = (data) => {
  const keys = Object.keys(data)
  if (keys.includes("email") && keys.includes("mobileNumber")) {
    return Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .error((errors) =>
          errors.map((err) => {
            err.message = "Vänligen fyll i giltig e-postadress"
            return err
          })
        ),
      mobileNumber: Joi.string()
        .min(6)
        .regex(/^([+])?(\d+)$/)
        .required()
        .error((errors) => {
          const [error] = errors || []
          switch (error.code) {
            case "string.min":
              error.message = "Mobilnumret måste ha minst 6 siffror"
              break
            default:
              error.message = "Vänligen fyll i giltigt mobilnummer"
              break
          }
          return errors
        }),
    }).validate(data, {
      abortEarly: true,
    })
  }

  throw new Error("Okända fritexttyper")
}

export default multipleFreeTextValidation
