import Joi from "@hapi/joi"

const profileValidation = (data) =>
  Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .pattern(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i giltig e-post"
          return err
        })
      ),
    phoneNumber: Joi.string()
      .min(6)
      .regex(/^([+])?(\d+)$/)
      .allow("")
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i giltigt telefonnummer"
          return err
        })
      ),
  }).validate(data, {
    abortEarly: false,
  })

export default profileValidation
