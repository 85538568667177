import React, { useState } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import { Button, Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { Link } from "react-router-dom"
import profileValidation from "validation/profileValidation"
import { logout, updateUserProfile } from "redux/user"
import { unmatchCaregiver } from "api/unmatchCaregiver"
import { updateUser } from "api/updateUser"
import isFreeCardValid from "utils/isFreeCardValid"
import { TouchClick } from "../../../components/TouchButton"
import DividerText from "../../../components/DividerText"
import Input from "../../../components/Input"
import UnmatchCaregiverConfirmationDialog from "../../../components/UnmatchCaregiverConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  formInputContainer: {
    [theme.breakpoints.up("sm")]: {
      "& div": {
        marginRight: theme.spacing(4),
      },
      "& div:last-child": {
        marginRight: 0,
      },
    },
  },
  formInputs: {
    paddingBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  formSections: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.dark_gray,
  },
  formButtonsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2, 0, 3),
    justifyContent: "space-around",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  formButtons: {
    width: 120,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
  },
  changeCaregiverContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    width: "230px",
    "& button": {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    "& p": {
      fontSize: "0.7rem",
      fontStyle: "italic",
      fontWeight: "500",
      color: theme.palette.black,
      textAlign: "center",
    },
  },
  logOutButton: {
    display: "inline-block",
  },
}))

const resolver = (data) => {
  const { email, phoneNumber } = data
  const { error, value } = profileValidation({ email, phoneNumber })
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const Form = ({ editUser, userLogout, user }) => {
  const {
    id: userId,
    email,
    firstName,
    lastName,
    phoneNumber,
    meeting: videoMeeting,
    freeCard,
  } = user

  const { cardNumber, expirationDate } = freeCard

  const isFreeCardActive = isFreeCardValid(expirationDate)

  const classes = useStyles()
  const { control, handleSubmit, errors } = useForm({
    resolver,
  })
  const [
    openUnmatchCaregiverConfirmationDialog,
    setOpenUnmatchCaregiverConfirmationDialog,
  ] = useState(false)
  const [
    unmatchCaregiverConfirmationDialogError,
    setUnmatchCaregiverConfirmationDialogError,
  ] = useState(null)

  const onSubmit = async (data) => {
    await updateUser(userId, data)
    editUser({ data, shouldShowAlert: true })
  }

  const handleUnmatchFromCaregiver = async () => {
    try {
      await unmatchCaregiver(userId)
      window.location.replace("/")
    } catch (error) {
      setUnmatchCaregiverConfirmationDialogError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  const handleOpenUnmatchCaregiverConfirmationDialog = () => {
    setUnmatchCaregiverConfirmationDialogError(null)
    setOpenUnmatchCaregiverConfirmationDialog(true)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="firstName"
          label="Förnamn"
          defaultValue={firstName}
          control={control}
          errors={errors}
          disabled
        />
        <Input
          className={classes.formInputs}
          name="lastName"
          label="Efternamn"
          defaultValue={lastName}
          control={control}
          errors={errors}
          disabled
        />
      </Box>

      <DividerText className={classes.formSections} text="Kontaktuppgifter" />

      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="email"
          label="Email"
          defaultValue={email}
          control={control}
          errors={errors}
        />
        <Input
          className={classes.formInputs}
          name="phoneNumber"
          label="Telefon"
          type="tel"
          defaultValue={phoneNumber || ""}
          control={control}
          errors={errors}
        />
      </Box>
      {isFreeCardActive && (
        <>
          <DividerText className={classes.formSections} text="Frikort" />

          <Box className={classes.formInputContainer}>
            <Input
              className={classes.formInputs}
              name="cardNumber"
              label="Nummer"
              defaultValue={cardNumber}
              disabled
              control={control}
              errors={errors}
            />
            <Input
              className={classes.formInputs}
              name="expirationDate"
              label="Utgångsdatum"
              defaultValue={format(new Date(expirationDate), "yyyy-MM-dd")}
              control={control}
              errors={errors}
              disabled
            />
          </Box>
        </>
      )}

      <DividerText className={classes.formSections} text="Konto" />
      {!videoMeeting?.start && (
        <>
          <Box className={classes.changeCaregiverContainer}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleOpenUnmatchCaregiverConfirmationDialog()}
            >
              Byt psykolog
            </Button>
          </Box>
          <UnmatchCaregiverConfirmationDialog
            openDialog={openUnmatchCaregiverConfirmationDialog}
            onClose={() => setOpenUnmatchCaregiverConfirmationDialog(false)}
            onUnmatch={handleUnmatchFromCaregiver}
            error={unmatchCaregiverConfirmationDialogError}
          />
        </>
      )}
      <Box sx={{ paddingBottom: 2 }}>
        <TouchClick
          className={classes.logOutButton}
          display="block"
          onClick={() => {
            userLogout()
          }}
        >
          <ExitToAppIcon color="primary" />
          <Box ml={1}>
            <Typography color="primary">Logga ut</Typography>
          </Box>
        </TouchClick>
      </Box>

      <Box className={classes.formButtonsContainer}>
        <Button
          className={classes.formButtons}
          variant="outlined"
          component={Link}
          to="/"
          color="default"
        >
          Avbryt
        </Button>
        <Button
          className={classes.formButtons}
          variant="contained"
          type="submit"
        >
          Spara
        </Button>
      </Box>
    </form>
  )
}

const mapStateToProps = (state) => {
  const { user } = state

  return { user }
}

const mapDispatchToProps = (dispatch) => ({
  editUser: ({ data, shouldShowAlert }) =>
    dispatch(updateUserProfile({ data, shouldShowAlert })),
  userLogout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
